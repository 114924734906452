import React, {ReactNode, useEffect, useRef} from "react";
import styles from '../mna-info.module.scss';
import classnames from 'classnames/bind';
import {Field, FormikValues, useFormikContext} from "formik";
import {InputBox, NumberInputBox} from "../edit-common";
import {useInjection} from "inversify-react";
import {DialogManager} from "../../../components/dialog-manager";
import BusinessFieldPop from "./business-field-pop";
import { CustomerDetail } from "../../../model/customer/dto/customer-detail";
import { CompanyPfKw, EarningsEstimate } from "../../../model/customer/dto/customer-mna-brokerage-dto";
import { PrFrStatCont } from "../../../model/customer/dto/financial-statement";

const cx = classnames.bind(styles);

interface TextAreaBoxProps {
    name: string;
    title?: ReactNode;
    isInnerTitle?: boolean;
    initialH?: string;
}

export const TextAreaBox = ({name, title, isInnerTitle, initialH = '7.2rem'}: TextAreaBoxProps) => {
    const visibleRef = useRef<HTMLTextAreaElement|null>(null);

    const adjustHeight = () => {
        if (visibleRef.current) {
            visibleRef.current.style.height = 'auto';
            visibleRef.current.style.height = `${visibleRef.current.scrollHeight}px`; // 내용에 맞게 높이 조정
        }
    };

    useEffect(() => {
        if (visibleRef.current) {
            visibleRef.current.style.height = `${visibleRef.current.scrollHeight}px`;
        }
    }, []);

    return (
        <div className={cx('text-area-box')}>
            {title && <label className={cx(isInnerTitle ? 'inner-title' : '')}>{title}</label>}
            <Field innerRef={visibleRef} onInput={adjustHeight} name={name} as='textarea' placeholder='입력' style={{minHeight: initialH}}/>
        </div>
    );
}

const CompanyTypeForm = ({data, info, perform} : {data: CustomerDetail, info: CompanyPfKw[], perform: EarningsEstimate[]}) => {
    const dialogManager = useInjection(DialogManager);
    const {setFieldValue} = useFormikContext<FormikValues>();
    const today = new Date();
    
    let sales;
    let profit;
    let growth;

    const fss = data.fss && data.fss.length > 0 ? data.fss : undefined;
    // let lastYear = today.getFullYear() - 1;

    // if (perform !== null && perform && perform.length > 0) {
    //     lastYear = perform[0].year - 1;
    // }

    /**
     * 25.01.02
     * 일정 변경 요청사항이 있기 전까지 해당 년도 값으로 고정
     */
    const lastYear = 2023;

    if (fss) {
        for (let i = 0; i < fss[0].iss.length; i++) {
            const iss: PrFrStatCont = fss[0].iss[i];
            const stDate = Number(iss.stDate.substring(0, 4));

            if(lastYear === stDate) {
                sales = Number(iss.data["sales"] ?? 0);
                const opIncome = Number(iss.data["opIncome"] ?? 0);
                profit = sales === 0 ? 0 : (opIncome / sales) * 100;

                if (fss[0].iss.length > 1) {
                    if(i !== fss[0].iss.length - 1) {
                        const prevIss = fss[0].iss[i + 1];
                        const prevSales = Number(prevIss.data["sales"] ?? 0);
                        growth = prevSales === 0 ? 0 : (sales / prevSales) - 1;
                    }
                }

                break;
            }
        }
    }

    return (
        <div className={cx('company-type-form')}>
            <TextAreaBox title='1. 주요 제품' name={'brokerage.mainProduct'}/>
            <p className={cx('sub-title')}>2. 사업영역</p>
            <InputBox name={'businessName'} inputWidth='100%' readOnly placeholder='입력' inputboxCn={cx('select-box')}
                      onClick={() => {
                          dialogManager.open(BusinessFieldPop, {info: info, name: "brokerage",  setFieldValue: setFieldValue});
                      }}/>
            <TextAreaBox
                title='3. 사업분야 상세 설명'
                name='brokerage.bizCateDetail'
                initialH='15rem'/>
            <p className={cx('sub-title', 'gap2')}>4. {lastYear + 1}년 매출액 및 영업이익 예상치, 향후 5년간 실적 전망</p>
            <div className={cx('flex-row-box')}>
                <div className={cx('flex-column-box')}>
                    <p className={cx('opacity-0')}>제목</p>
                    <p className={cx('no-input-box')}>매출액</p>
                    <p className={cx('no-input-box')}>매출액증가율</p>
                    <p className={cx('no-input-box')}>영업이익률</p>
                </div>
                <div className={cx('flex-column-box')}>
                    <p>{lastYear}년</p>
                    {
                        fss ?
                            <>
                                <p className={cx('no-input-box')}>{sales ? (sales / 1e5).toFixed(2) + "억원" : "-"}</p>
                                <p className={cx('no-input-box')}>{growth ? (growth * 100).toFixed(1) + "%" : "-"}</p>
                                <p className={cx('no-input-box')}>{profit ? (profit).toFixed(1) + "%" : "-"}</p>
                            </>
                            :
                            <>
                                <p className={cx('no-input-box')}>-</p>
                                <p className={cx('no-input-box')}>-</p>
                                <p className={cx('no-input-box')}>-</p>
                            </>
                    }

                </div>
                {
                    perform.map((data, i) => {
                        return (
                            <div key={data.year} className={cx('flex-column-box')}>
                                <p>{data.year}년</p>
                                <NumberInputBox name={`perform.${i}.sales`} unit='억원' inputWidth='10.4rem'/>
                                <NumberInputBox name={`perform.${i}.salesGrowthRate`} unit='%' inputWidth='10.4rem'/>
                                <NumberInputBox name={`perform.${i}.profitRate`} unit='%' inputWidth='10.4rem'/>
                            </div>
                        )
                    })
                }
            </div>
            <TextAreaBox name='brokerage.earningsEstimatesNote'/>
            <TextAreaBox
                title='5. 해당 기업의 주요 매출처 및 매출규모'
                name='brokerage.mainSalesChannel'
                initialH='15rem'/>
            <TextAreaBox
                title='6. 사업용부동산 및 생산라인'
                name='brokerage.estateAndProdLine'
                initialH='15rem'/>
            <TextAreaBox
                title='7. 주요 경쟁자'
                name='brokerage.mainCompetitors'
                initialH='15rem'/>
            <TextAreaBox
                title='8. 해당 사업 아이템의 경쟁우위 요소'
                name='brokerage.uniqueSellingPoints'
                initialH='15rem'/>
            <TextAreaBox
                title='9. 기술사항 / 연구 및 개발 중인 사항'
                name='brokerage.technicalDetails'
                initialH='15rem'/>
            <TextAreaBox
                title='10. 해당 기업을 인수했을 때 시너지를 낼 수 있는 기업 (사업분야)'
                name='brokerage.synergisticPartners'
                initialH='15rem'/>
            <p className={cx('divide-line-info')}>※ (11번 ~ 17번) M&A 어드바이저가 고객 상담 후 추가로 입력하는 항목입니다.</p>
            <TextAreaBox
                title='11. 기업매각사유'
                name='brokerage.salesReason'/>
            <TextAreaBox
                title={<p className={cx('plus_info')}>12. 희망하는 매각방식 <span>(*특정 조건 있을 경우 한에)</span></p>}
                name='brokerage.salesMethod'/>
            <TextAreaBox
                title='13. 매각희망금액 (산정 근거)'
                name='brokerage.salesAmount'/>
            <TextAreaBox
                title='14. 과거 기업인수 제안 받은 경험'
                name='brokerage.experRecvAcqOffer'/>
            <TextAreaBox
                title='15. 주주 구성 (대주주, 임직원, 투자자 지분율)'
                name='brokerage.shareHolderComposition'/>
            <TextAreaBox
                title='16. 대표이사 이외 회사 경영 실무를 이끌어 갈 수 있는 핵심 임원 존재 여부'
                name='brokerage.keyExecutives'/>
            <TextAreaBox
                title='17. 매각이 어려울 경우 생각해 둔 B플랜 여부'
                name='brokerage.planB'/>
            <div className={cx('btn-area')}>
                <button className={cx('save-btn')} type='submit' onClick={() => {
                }}><i></i>저장하기
                </button>
            </div>
        </div>
    );
}

export default CompanyTypeForm;