import React, {ChangeEvent, useEffect, useMemo, useState} from 'react';
import styles from './customer-detail-pop.module.scss';
import classNames from 'classnames/bind';
import PersonalData from './personal-data';
import MemberState from '../report/summary/member-state';
import TitleBox from '../../components/title-box';
import CompanyOverview from '../report/summary/company-overview';
import {useInit} from '../../util/util';
import {CustomerDetail} from '../../model/customer/dto/customer-detail';
import {useInjection} from 'inversify-react';
import {CustomerApi} from '../../model/customer/customer-api';
import {useTab} from '../detail-common';
import ConnectCoTalk from '../../components/connect-cotalk';
import {CustomerType} from '../../model/customer/dto/customer';
import ConsultLog from './consult-log';
import {ClosableProps} from '../../components/dialog';
import EditCompanyInfo from './edit-company-info';
import {useNavigate} from 'react-router-dom';
import {CustomerDetailTab} from '../../data/const_list';
import {CorporateRegiCopy} from '../report/summary/corporate-regi-copy';
import FinancialInfo from "../report/summary/financial-info";
import {DialogManager} from '../../components/dialog-manager';
import {PrCorpRegRoot} from "../../model/customer/dto/pr-corp-reg-root";
import ShareCustomerPop from "../../components/share-customer-pop";
import {removeEngName, ymdToDateString} from "../../util/str-util";
import {toast} from "react-toastify";
import LoadingDialog from "../../components/loading-dialog";
import NotiBotPop from '../../components/noti-bot-pop';
import {RepInfo} from "../../model/customer/dto/rep-info";
import HomeTaxSearch from "./hometax-search";
import PolicyFundTab from "./policy-fund-tab";
import {isSoleReport} from "../report/report-util";
import MnAInfo from "./mna-info";
import { CustomerInputApi } from '../../model/customer/customer-input-api';
import { CustomerMnaNeedsDto } from '../../model/customer/dto/customer-mna-needs-dto';

const cx = classNames.bind(styles);

const Company = ({
                     data,
                     setData,
                     edit,
                    corpReg,
                 }: { data: CustomerDetail, setData: React.Dispatch<React.SetStateAction<CustomerDetail>>, edit: boolean, corpReg?: PrCorpRegRoot }) => {
    const [editInfo, setEditInfo] = useState(edit);
    if (!data.customer || !data.summary) return null;
    return editInfo ?
        <EditCompanyInfo data={data} setData={setData}
                         onClose={() => setEditInfo(false)}/> :
        <>
            <TitleBox title='기업개요' paddingTop='4.0rem'
                      desc='※ NICE평가정보(주) 제공 데이터와 등기부등본 조회 정보가 적용됩니다.'>
                <button className='edit-btn' onClick={() => setEditInfo(true)}><i></i><span>수정</span></button>
            </TitleBox>
            <CompanyOverview summary={data.summary} corpReg={corpReg} companyInfo={data.customer?.companyInfo} rep={data.customer?.rep[0]} enpTyp={data.customer.enpTyp} />
        </>
}

interface CustomerDetailPopProps extends ClosableProps {
    customerId: string;
    tab?: string;
    edit?: boolean;
    secondTab?: string;
    repInfo?: RepInfo;
}

const CustomerDetailPop = ({customerId, tab, edit, secondTab, repInfo, onClose}: CustomerDetailPopProps) => {
    const customerApi = useInjection(CustomerApi);
    const customerInputApi = useInjection(CustomerInputApi);
    const dialogManager = useInjection(DialogManager);
    const [data, setData] = useInit<CustomerDetail>(customerApi.detailV2, [customerId], {});
    
    const shareMemberNo = data.share && data.share !== null ? data.share.srcMember.memberNo : "";
    // const [brokerage, setBrokerage] = useInit<CustomerMnaBrokerageDto>(customerInputApi.getMnaBrokerage, [customerId, shareMemberNo], {});
    // const summary = data.summary;

    const [needs, setNeeds] = useState<CustomerMnaNeedsDto>();

    const getNeeds = async () => {
        const result = await customerInputApi.getMnaNeeds(customerId, shareMemberNo);
        setNeeds(result.val!);
    }

    let [registerCus, setRegisterCus] = useState(false);
    let [consultingCus, setConsultingCus] = useState(false);
    let [insuranceCus, setInsuranceCus] = useState(false);

    const onChangeCus = async (e: ChangeEvent<HTMLInputElement>, type: string) => {
        const customerType: CustomerType = {
            registerCus: registerCus,
            consultingCus: consultingCus,
            insuranceCus: insuranceCus
        }

        if(type === 'register') {
            customerType.registerCus = e.target.checked;
            setRegisterCus(e.target.checked);
        } else if(type === 'consulting') {
            customerType.consultingCus = e.target.checked;
            setConsultingCus(e.target.checked);
        } else if(type === 'insurance') {
            customerType.insuranceCus = e.target.checked;
            setInsuranceCus(e.target.checked);
        }

        await customerApi.updateCustomerType(customerId, customerType);
    }

    const onRefreshCustomer = async () => {
        const result = await customerApi.detailV2(customerId);

        if (result.success && result.val) {
            setData(result.val);
            toast.success("새로고침 되었습니다");
        }
    }

    const tabList = useMemo(() => [
        {
            id: CustomerDetailTab.Personal,
            name: '인적사항',
            comp: PersonalData,
            props: {data, setData,
                repInfo: repInfo, cusDetailPopClose: onClose
            }
        },
        {
            id: CustomerDetailTab.Summary,
            name: '기업개요',
            comp: Company,
            props: {data, setData, corpReg: data.corpReg,}
        },
        {
            id: CustomerDetailTab.Member,
            name: '주주 및 임원 현황',
            comp: MemberState,
            props: {
                data: data.summary,
                customer: data.customer,
                corpReg: data.corpReg,
                setData: setData
            },
            isShow: () => {
                return !isSoleReport(data);
            }
        },
        {
            id: CustomerDetailTab.Financial,
            name: '상세 재무정보',
            comp: FinancialInfo,
            props: {
                summary: data.summary,
                customer: data.customer,
                customerId: customerId,
                data: data.fss,
                fsYear: data.summary?.FS_YEAR,
                bsTbl: data.bsTbl,
                isTbl: data.isTbl,
                secondTab: secondTab,
                fsLoadType: data.customer?.fsLoadType,
                setData: setData,
                v: data.customer?.v,
                loadType: data.loadType,
                ownerHometaxData: data.ownerHometaxData
            }
        },
        {
            id: CustomerDetailTab.RegCorpCopy,
            name: '법인등기부등본',
            comp: CorporateRegiCopy,
            props: {corpReg: data.corpReg, summary: data.summary, customer: data.customer},
            isShow: () => !isSoleReport(data)
        },
        {
            id: CustomerDetailTab.Counselling,
            name: '상담일지',
            comp: ConsultLog,
            props: {customerId: customerId, flags: data.customer?.flags}
        },
        {
            id: CustomerDetailTab.HomeTax,
            name: '홈택스 자료조회',
            comp: HomeTaxSearch,
            props: {data: data},
            // isShow: () => {
            //     if(data.customer?.connectionState === "CONNECTED" && data.customer?.connectId && data.ownerHometaxData) {
            //         return true;
            //     } else {
            //         return false;
            //     }
            // }
        },
        {
            id: CustomerDetailTab.PolicyFund,
            name: '맞춤형 정책자금',
            comp: PolicyFundTab
        },
        {
            id: CustomerDetailTab.MnAInfo,
            name: 'M&A 니즈',
            comp: MnAInfo,
            props: {data: data, needs: needs, setData: setData, setNeeds: setNeeds}
        },
    ], [customerId, data, setData, needs, setNeeds]);

    let [menu, content] = useTab(tabList, data, cx, tab, edit);

    const navigate = useNavigate();

    const customer = data.customer;
    const share = data.share;
    const loadType = data.loadType;

    const notiBotPop = () => {
        dialogManager.open(NotiBotPop, {data: customer!});
    }

    useEffect(() => {
        if (data.customer) {
            setRegisterCus(data.customer.registerCus ?? false);
            setConsultingCus(data.customer.consultingCus ?? false);
            setInsuranceCus(data.customer.insuranceCus ?? false);

            getNeeds();
        }

    }, [data.customer]);

    return (
        <div className='full-background'>
            <div className={`pop-inner ${cx('pop-wrap')}`}>
                <div className={cx('left-menu')}>
                    <div className={cx('customer-card')}>
                        <img src='/images/empty_logo.png' alt='사진 없을 경우'/>
                        <p className={cx('company-name')}>{customer && removeEngName(customer.enpNm)}</p>
                        {customer?.rep.map((pi) =>
                            <div key={pi.name} className={cx('cus-name-box')}>
                                <p className={cx('cus-name')}>{pi.name} <span>대표</span></p>
                                {customer && loadType === 'MS' &&
                                    <div className={cx('cotalk-state')}>
                                        <ConnectCoTalk customerId={customerId}
                                                       companyName={customer.enpNm}
                                                       selectedRep={pi}
                                                       refresh={() => onRefreshCustomer()}
                                                       onCloseCustomerDetailPop={onClose}/>
                                    </div>
                                }
                                {loadType === 'SHARE' && <div className={cx('share-cus-chk')}>공유고객</div>}
                            </div>
                        )}
                        <div className={cx('data-area')}>
                            {
                                loadType === 'MS' && <p><i></i>고객등록일 {ymdToDateString(customer?.createAt.substring(0, 10))}</p>
                            }

                            {loadType === 'SHARE' &&
                                <p><i></i>고객공유일 {ymdToDateString(share?.shareDate.substring(0, 10))}</p>
                            }
                        </div>
                    </div>
                    <div className={cx('menu-scroll')}>
                        {customer && menu}
                        {
                            (customer?.connectionState === "CONNECTED" && customer?.connectId) &&
                            <>
                                <a className={cx('policy-funds-link')} onClick={() => { notiBotPop() }}><i></i><span>맞춤형 정책자금</span></a>
                            </>
                        }
                        {
                            loadType === 'MS' && customer?.linkCusId && <>
                                <div className={cx('company-info-btn-area')}>
                                    <button onClick={() => {
                                        // navigate('/customer/customerState', {state: {customerId: customer?.linkCusId, tab: CustomerDetailTab.Personal}});

                                        navigate(`/customer/customerState/${customer?.linkCusId}/${CustomerDetailTab.Personal}`);
                                    }}>{
                                        customer?.v === "MAIN" ? "이전 고객 정보" : "최근 기업 정보"
                                    }
                                    </button>
                                </div>
                            </>
                        }
                    </div>
                </div>
                <div className={cx('right-tab')}>
                    <div className={cx('btn-area')}>
                        <div className={cx('chk-box-area')}>
                            <p>고객구분</p>
                            <label htmlFor='registerCus' className={cx('chk-box')}>
                                <input name='registerCus' type='checkbox' checked={registerCus} onChange={(e) => onChangeCus(e, 'register')} />
                                기장고객
                            </label>
                            <label htmlFor='consultingCus' className={cx('chk-box')}>
                                <input name='consultingCus' type='checkbox' checked={consultingCus} onChange={(e) => onChangeCus(e, 'consulting')} />
                                컨설팅고객
                            </label>
                            <label htmlFor='insuranceCus' className={cx('chk-box')}>
                                <input name='insuranceCus' type='checkbox' checked={insuranceCus} onChange={(e) => onChangeCus(e, 'insurance')} />
                                보험고객
                            </label>
                        </div>
                        {(loadType === 'SHARE' || customer?.lastShareDate) && <button className={cx('reset-btn')} onClick={() => onRefreshCustomer()}><i></i>공유정보 새로고침</button>}
                        <button className={cx('co-report-btn')}
                                onClick={() => {
                                    // if (data.customer?.enpTyp === "2") {
                                    //     navigate(`/co-report-sole/${customer?.id}`);
                                    //     onClose();
                                    // }
                                    // else if(!data.fss || data.fss!.every((it) => it.temp)) {
                                    if(!data.fss || data.fss!.every((it) => it.temp)) {
                                        data.customer?.fsLoadType === "START" ?
                                        dialogManager.open(LoadingDialog, {
                                            aniW: '10.0rem',
                                            aniH: '10.0rem',
                                            jsonFile: 'loading',
                                            title: '잠시만 기다려 주세요.',
                                            autoOff: true
                                        }) :
                                        dialogManager.alert('재무정보 미반영시 리포트 생성이 불가능합니다.','수동입력을 진행해 주세요.', () => {
                                            // navigate('/customer/customerState', {state:{
                                            //         customerId: customer?.id,
                                            //         tab: CustomerDetailTab.Financial
                                            //     }});

                                            navigate(`/customer/customerState/${customer?.id}/${CustomerDetailTab.Financial}`);
                                        });
                                    }
                                    else {
                                        // navigate('/co-report', {state: {customer}});

                                        navigate(isSoleReport(data) ? `/co-report-sole/${customer?.id}` : `/co-report/${customer?.id}`);

                                        onClose();
                                    }
                                }}
                        ><i></i>씨오리포트
                        </button>

                        {customer?.v === "MAIN" && <button type='button' className={cx('share-btn')} onClick={() => dialogManager.open(ShareCustomerPop, {customerId: customerId, loadType: loadType})}><i></i>공유하기</button>}

                        {/* {loadType === 'MS' && customer?.v === "MAIN" && <button type='button' className={cx('share-btn')} onClick={() => dialogManager.open(ShareCustomerPop, {customerId: customerId})}><i></i>공유하기</button>} */}


                        {/*<SummaryPdfDownloadV2 data={summary} fs={data.fss} bsTbl={data.bsTbl!} isTbl={data.isTbl!} corpReg={data.corpReg!} className={cx('summary-report')}/>*/}
                        {/*<CorpPdfDownload data={data.summary} corpReg={data.corpReg!} customer={customer!} className={cx('summary-report')}/>*/}
                        {/*<FsPdfDownload data={data.summary} fs={data.fss!} bsTbl={data.bsTbl!} isTbl={data.isTbl!} className={cx('summary-report')}/>*/}
                        <button className={cx('close-btn')} onClick={() => {
                            onClose();
                        }}>닫기
                        </button>
                    </div>
                    {content}
                </div>
            </div>
        </div>
    );
};

export default CustomerDetailPop;