import React from "react";
import styles from '../mna-info.module.scss';
import classnames from 'classnames/bind';
import {TextAreaBox} from "./company-type-form";

const cx = classnames.bind(styles);

const AcquirerInfoForm = () => {

    return (
        <div className={cx('company-type-form')}>
            <TextAreaBox title='1. 인수기업 주요 제품' name={'acq.mainProduct'}/>
            <TextAreaBox
                title='2. 인수기업 사업영역'
                name='acq.bizAreaInfo'
                initialH='15rem'/>
            <TextAreaBox
                title='3. M&A 목적'
                name='acq.mnaPurpose'
                initialH='15rem'/>
            <TextAreaBox
                title='4. 기존 인수경험'
                name='acq.experAcq'
                initialH='15rem'/>
            <p className={cx('separate-label')}>5. 희망인수조건</p>
            <TextAreaBox
                title='1) 주요서비스 / 사업영역'
                name='acq.acqHopeTerms.acqTerms1'
                isInnerTitle={true}
                initialH='15rem'/>
            <TextAreaBox
                title='2) 매출액 / 영업이익'
                name='acq.acqHopeTerms.acqTerms2'
                isInnerTitle={true}/>
            <TextAreaBox
                title='3) 경영기간 / 근로자수 / 지역'
                name='acq.acqHopeTerms.acqTerms3'
                isInnerTitle={true}/>
            <TextAreaBox
                title='4) 연구소 및 기업인증 / 특허 및 기술'
                name='acq.acqHopeTerms.acqTerms4'
                isInnerTitle={true}/>
            <TextAreaBox
                title='5) 공장보유 여부'
                name='acq.acqHopeTerms.acqTerms5'
                isInnerTitle={true}/>
            <TextAreaBox
                title='6) 기타조건'
                name='acq.acqHopeTerms.acqTerms6'
                isInnerTitle={true}/>
            <div className={cx('btn-area')}>
                <button className={cx('save-btn')} type='submit' onClick={() => {
                }}><i></i>저장하기
                </button>
            </div>
        </div>
    );
}

export default AcquirerInfoForm;